import './ProductTable.scss';
import {Product} from "./model";
import pdf_icon from './assets/pdficon_small.png';
import DOMPurify from 'dompurify';

export interface TableColumn {
    field: keyof Product;
    label: string;
}

export interface ProductTableProps {
    records: Product[];
    additionalColumn?: TableColumn;
}

export default function ProductTable( props: ProductTableProps ): JSX.Element {
    return (
        <>
        { props.records.length === 0 &&
            <div className='no-records-found'>No products found. Please change criteria and try again.</div>
        }
        { props.records.length > 0 &&
            <table className='table-products'>
            <thead>
                <tr>
                    <th>Product</th>
                    <th>Strength<br/>(%)</th>
                    <th>Concentration<br/>(mg per mL)</th>
                    <th>Fill<br/>Volume<br/>(mL)</th>
                    <th>Delivery<br/>System</th>
                    <th>Contains<br/>Epinephrine</th>
                    <th>Contains<br/>Preservatives</th>
                    <th>Pack<br/>Size</th>
                    <th>Minimum<br/>Direct Order<br/>Quantity</th>
                    <th>NDC Number</th>
                    <th>PI</th>
                    <th>SDS</th>
                    { props.additionalColumn &&
                        <th className='wholesaler-number'>{props.additionalColumn.label}</th>
                    }
                </tr>
            </thead>
            <tbody>
                { props.records.map((product: Product, index: number) => (
                    <tr key={index}>
                        <td dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(product.productNameNormalized)}}></td>
                        <td className='strength'>{product.strength}</td>
                        <td className='concentration'>{product.concentration}</td>
                        <td>{product.fillVolume}</td>
                        <td dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(product.deliverySystem)}}></td>
                        <td>{product.containsEpinephrine}</td>
                        <td>{product.containsPreservatives}</td>
                        <td>{product.packSize}</td>
                        <td>{product.directOrderQty}</td>
                        <td className='ndc-number'>{product.ndcNumber}</td>
                        <td>
                            {product.fullLinkPI &&
                                <a href={product.fullLinkPI}
                                   title="Prescription Information PDF Link"
                                   aria-label="View Prescription Information PDF. Opens In New Window"
                                   rel="noreferrer"
                                   target="_blank">
                                    <img src={pdf_icon} alt="PDF Icon"/>
                                </a>
                            }
                        </td>
                        <td>
                            {product.fullLinkSDS &&
                                <a href={product.fullLinkSDS}
                                   title="Safety Data Sheet PDF Link"
                                   aria-label="View Safety Data Sheet PDF. Opens In New Window"
                                   rel="noreferrer"
                                   target="_blank">
                                    <img src={pdf_icon} alt="PDF Icon"/>
                                </a>
                            }
                        </td>
                        { props.additionalColumn &&
                            <td className='wholesaler-number'>{product[props.additionalColumn.field]}</td>
                        }
                    </tr>
                ))}
            </tbody>
        </table>
        }
        </>
    );
}
