import { DOCUMENTS_BASE_URL, Product, ProductApiRecord } from "./model";

function httpError(response: Response): string {
    return `HTTP call failed. Url: '${response.url}'; status: '${response.status}'; statusText: '${response.statusText}'`;
}

function cannotMakeHttpRequest(request: URL, error: any): string {
    return `Cannot send HTTP request. Url: '${request.href}'; error message: '${error['message']}'`;
}

export async function fetchProducts(): Promise<Product[]> {
    // The URL below is an AWS ClodFront for:
    // http://editor.fresenius-kabi.us:1024/api/anesthesia
    // It is required to support HTTPS protocol to avoid 'mixed content' browser errors when this website gets served
    // from under HTTPS
    const request = new URL('https://d1q2b16j00fe21.cloudfront.net');
    try {
        const response: Response = await fetch(request);
        if ( !response.ok ) {
            return Promise.reject( httpError(response) );
        }
        return response.json().then((records: ProductApiRecord[]) =>
            records.map(( record: ProductApiRecord) => enhanceProductApiRecord(record) )
                    // sort by Product Name
                    .sort((first: Product, second: Product) => {
                        const firstProductName = first.productNameNormalized;
                        const secondProductName = second.productNameNormalized;
                        if ( typeof firstProductName === 'string' && typeof secondProductName === 'string' ) {
                            return firstProductName.localeCompare(secondProductName);
                        } else if ( firstProductName === undefined && secondProductName !== undefined) {
                            return 1;
                        } else if ( firstProductName !== undefined && secondProductName === undefined ) {
                            return -1;
                        } else { // both are undefined
                            return 0;
                        }
                    })
        );
    } catch (e) {
        // client-side error: browser cannot make an HTTP request
        return Promise.reject( cannotMakeHttpRequest(request, e) );
    }
}

function enhanceProductApiRecord(record: ProductApiRecord): Product {
    const result: Product = {...record} as Product;
    // normalize Product Name
    result.productNameNormalized = normalizeProductName(result);
    // build full document links
    if ( result.linkPI ) {
        result.fullLinkPI = `${DOCUMENTS_BASE_URL}${result.linkPI}`;
    }
    if ( result.linkSDS ) {
        result.fullLinkSDS = `${DOCUMENTS_BASE_URL}${result.linkSDS}`;
    }
    // enhance 'strength' field
    const strengthAsPercent: number | undefined = parseStrength(result.strength);
    result.strengthAsNumber = strengthAsPercent;
    result.strengthNormalized = (strengthAsPercent !== undefined) ? `${strengthAsPercent}%` : result.strength;
    // enhance 'fill volume' field
    const fillVolumeAsNumber: number | undefined = parseFillVolume(result.fillVolume);
    result.fillVolumeAsNumber = fillVolumeAsNumber;
    result.fillVolumeNormalized = (fillVolumeAsNumber !== undefined) ? `${fillVolumeAsNumber} mL` : result.fillVolume;
    return result;
}


function parseStrength(rawStrength: string): number | undefined {
    if (!rawStrength) {
        return undefined;
    }
    // Parses raw api 'strength' values. They expected to start with percentage (for example: '0.2% (400 mg per 200 mL)')
    // Extract 'percentage' part and convert it to a number.
    const regexpStrength =  /^(?<strengthAsPercent>\d{0,2}(?:\.\d{1,4})?)%.*$/m
    const match: RegExpMatchArray | null = rawStrength.match(regexpStrength);
    const strengthFound: string | undefined = match?.groups?.strengthAsPercent;
    if ( !strengthFound ) {
        return undefined;
    }
    return parseFloat(strengthFound);
}

function parseFillVolume(rawFillVolume: string): number | undefined {
    // Parses raw api 'fill volume' values. Expects following samples: '123ml', '123 mL', '123 ML', '123 mlsfdgsdgdf'.
    // Extract 'fill volume' part and convert it to a number.
    const regexpFillVolume =  /^(?<fillVolumeAsNumber>\d{1,5}) *[Mm][Ll].*$/m
    const match: RegExpMatchArray | null = rawFillVolume.match(regexpFillVolume);
    const fillVolumeFound: string | undefined = match?.groups?.fillVolumeAsNumber;
    if ( !fillVolumeFound ) {
        return undefined;
    }
    return parseInt(fillVolumeFound);
}

function normalizeProductName(rawProduct: ProductApiRecord): string {
    if ( rawProduct.brandedName && rawProduct.nameExtension ) {
        return `${rawProduct.brandedName} ${rawProduct.nameExtension}`;
    } else if ( !rawProduct.brandedName && rawProduct.nameExtension ) {
        return rawProduct.nameExtension;
    }
    return rawProduct.brandedName;
}
