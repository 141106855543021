export enum FacetID {
    product = 'product',
    strength = 'strength',
    preservatives = 'preservatives',
    epinephrine = 'epinephrine',
    fillVolume = 'fillVolume',
    deliverySystem = 'deliverySystem',
    wholesalerNumber = 'wholesalerNumber'
}

export interface FacetOption {
    value: string;
    label: string;
}

export interface FacetConfig {
    id: FacetID;
    label: string;
    generateOptions: (products: Product[]) => FacetOption[];
    evaluateProduct: (product: Product, facetValue: FacetValue) => boolean;
}

export interface FacetModel {
    id: FacetID;
    label: string;
    expanded: boolean;
    options: FacetOption[];
    selectedOption: FacetOption | undefined;
}

export type FacetValue = string;
export type MapFacetSelections = {[key in FacetID]: FacetValue};

export const DOCUMENTS_BASE_URL = 'http://editor.fresenius-kabi.us/';

export interface ProductApiRecord {
    trademark: string;
    amerisourceNumber: string;
    brandedName: string;
    cardinalNumber: string;
    concentration: string;
    containsEpinephrine: string;
    containsPreservatives: string;
    deliverySystem: string;
    directOrderQty: string;
    fillVolume: string;
    linkPI: string;
    linkSDS: string;
    mcKessonNumber: string;
    morrisNumber: string;
    nameExtension: string;
    ndcNumber: string;
    packSize: string;
    strength: string;
}

export type Product = ProductApiRecord & {
    fillVolumeAsNumber: number | undefined;
    fillVolumeNormalized: string;
    fullLinkPI: string;
    fullLinkSDS: string;
    productNameNormalized: string;
    strengthAsNumber: number | undefined;
    strengthNormalized: string;
}
