import './Facet.scss';
import React, {MouseEvent} from "react";
import {FacetID, FacetModel, FacetOption} from "./model";
import clearbtn from './assets/clear-btn.png';
import checkmark from './assets/check-mark.png';
import DOMPurify from "dompurify";

export type FacetProps = FacetModel & {
    onExpand: (facetId: FacetID, expand: boolean) => void;
    onSelect: (facetId: FacetID, selectedOption: FacetOption | undefined) => void;
}

export default function Facet( props: FacetProps ): JSX.Element {
    const onExpandClick = () => {
        if ( props.options?.length > 0) {
            props.onExpand(props.id, !props.expanded);
        }
    }

    const onClickOption = (selectedValue: string) => {
        const selectedOption: FacetOption | undefined = ( selectedValue ) ?
            props.options.find((option: FacetOption) => option.value === selectedValue ) : undefined;
        props.onSelect(props.id, selectedOption);
    }

    const onClearClick = (event: MouseEvent<HTMLImageElement>) => {
        props.onSelect(props.id, undefined);
        event.stopPropagation();
    }

    return (
        <div className={['facet',
                props.expanded ? 'facet-expanded' : 'facet-collapsed',
                props.selectedOption ? 'facet-selected' : ''].join(' ')
             }
             aria-expanded={props.expanded}
             onClick={onExpandClick}
        >
            <div className='facet-body'>
                <div className="facet-label"
                    role="button"
                    aria-pressed={props.selectedOption ? 'true' : 'false'}>
                    <span>{props.label}</span>
                    { props.selectedOption &&
                        <img src={clearbtn}
                             className="clear-btn"
                             alt="Clear Selection"
                             onClick={onClearClick}></img>
                    }
                </div>
                { props.selectedOption &&
                    <div className="facet-selected-option">
                        <img src={checkmark} className="check-mark" alt="Item Selected"></img>
                        <span className="desc-txt" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(props.selectedOption.label)}}/>
                    </div>
                }
                { props.options?.length > 0 &&
                    <span className="arrow-image"></span>
                }
            </div>
            { props.expanded &&
                <div className="facet-dropdown">
                    { props.options.map((option: FacetOption) =>
                        <div key={option.value}
                             className='facet-radio-button'
                             onClick={() => onClickOption(option.value)}>
                            <input className="radio-button"
                                   type='radio'
                                   readOnly={true}
                                   value={option.value}
                                   checked={option.value === props.selectedOption?.value}/>
                            <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(option.label)}}/>
                        </div>
                    )}
                </div>
            }
        </div>
    );
}
