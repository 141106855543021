import {FacetConfig, FacetID, FacetOption, FacetValue, MapFacetSelections, Product} from "./model";
import { uniqBy } from "lodash";

const FACET_PRODUCT: FacetConfig = {
    id: FacetID.product,
    label: 'Select a Product:',
    generateOptions: ( products: Product[] ): FacetOption[] => {
        return uniqBy( products||[], 'trademark')
            .filter((product: Product) => product.trademark)
            .map((product: Product) => product.trademark)
            .sort((a: string, b: string) => a.localeCompare(b))
            .map((value: string ) => ({ value: value, label: value}));
    },
    evaluateProduct: (product: Product, facetValue: FacetValue): boolean => {
        return (facetValue !== undefined) ? (product.trademark === facetValue) : true;
    }
};


const FACET_STRENGTH: FacetConfig = {
    id: FacetID.strength,
    label: 'Strength?',
    generateOptions:  (products: Product[] ): FacetOption[] => {
        return  uniqBy(products||[], 'strengthNormalized')
            .sort(( first: Product, second: Product) => {
                const firstNumber: number | undefined = first.strengthAsNumber;
                const secondNumber: number | undefined = second.strengthAsNumber;
                if ( typeof firstNumber === 'number' && typeof secondNumber === 'number') {
                    return firstNumber - secondNumber;
                } else if ( firstNumber === undefined && typeof secondNumber === 'number') {
                    return 1;
                } else if ( typeof firstNumber === 'number' && secondNumber === undefined ) {
                    return -1;
                } else { // both are undefined
                    return 0;
                }
            })
            .map((product: Product) => product.strengthNormalized )
            .map((value: string ) => ({ value: value, label: value}));
    },
    evaluateProduct: (product: Product, facetValue: FacetValue): boolean => {
        return (facetValue !== undefined) ? (product.strengthNormalized === facetValue) : true;
    }
};


const FACET_PRESERVATIVES: FacetConfig = {
    id: FacetID.preservatives,
    label: 'Contains Preservatives?',
    generateOptions: (products: Product[] ): FacetOption[] => {
        return uniqBy( products||[], 'containsPreservatives')
            .filter((product: Product) => product.containsPreservatives)
            .map((product: Product) => product.containsPreservatives)
            .sort((a: string, b: string) => b.localeCompare(a))
            .map((value: string ) => ({ value: value, label: value}));
    },
    evaluateProduct: (product: Product, facetValue: FacetValue): boolean => {
        return (facetValue !== undefined) ? (product.containsPreservatives === facetValue) : true;
    }
};


const FACET_EPINEPHRINE: FacetConfig = {
    id: FacetID.epinephrine,
    label: 'Contains Epinephrine?',
    generateOptions: (products: Product[] ): FacetOption[] => {
        return uniqBy( products||[], 'containsEpinephrine')
            .filter((product: Product) => product.containsEpinephrine)
            .map((product: Product) => product.containsEpinephrine)
            .sort((a: string, b: string) => b.localeCompare(a))
            .map((value: string ) => ({ value: value, label: value}));
    },
    evaluateProduct: (product: Product, facetValue: FacetValue): boolean => {
        return (facetValue !== undefined) ? (product.containsEpinephrine === facetValue) : true;
    }
};


const FACET_FILL_VOLUME: FacetConfig = {
    id: FacetID.fillVolume,
    label: 'Fill Volume?',
    generateOptions: (products: Product[] ): FacetOption[] => {
        return  uniqBy(products||[], 'fillVolumeNormalized')
            .sort(( first: Product, second: Product) => {
                const firstNumber: number | undefined = first.fillVolumeAsNumber;
                const secondNumber: number | undefined = second.fillVolumeAsNumber;
                if ( typeof firstNumber === 'number' && typeof secondNumber === 'number') {
                    return firstNumber - secondNumber;
                } else if ( firstNumber === undefined && typeof secondNumber === 'number') {
                    return 1;
                } else if ( typeof firstNumber === 'number' && secondNumber === undefined ) {
                    return -1;
                } else { // both are undefined
                    return 0;
                }
            })
            .map((product: Product) => product.fillVolumeNormalized )
            .map((value: string ) => ({ value: value, label: value}));
    },
    evaluateProduct: (product: Product, facetValue: FacetValue): boolean => {
        return (facetValue !== undefined) ? (product.fillVolumeNormalized === facetValue) : true;
    }
};


const FACET_DELIVERY_SYSTEM: FacetConfig = {
    id: FacetID.deliverySystem,
    label: 'Delivery System?',
    generateOptions: (products: Product[] ): FacetOption[] => {
        return uniqBy( products||[], 'deliverySystem')
            .filter((product: Product) => product.deliverySystem)
            .map((product: Product) => product.deliverySystem)
            .sort((a: string, b: string) => a.localeCompare(b))
            .map((value: string ) => ({ value: value, label: value}));
    },
    evaluateProduct: (product: Product, facetValue: FacetValue): boolean => {
        return (facetValue !== undefined) ? (product.deliverySystem === facetValue) : true;
    }
};


const WHOLESALER_NUMBER_OPTIONS: FacetOption[] = [
    { value: 'amerisourceNumber', label: 'Amerisource Bergen'},
    { value: 'cardinalNumber', label: 'Cardinal'},
    { value: 'mcKessonNumber', label: 'McKesson'},
    { value: 'morrisNumber', label: 'Morris-Dickson'}
];

const FACET_WHOLESALER_NUMBER: FacetConfig = {
    id: FacetID.wholesalerNumber,
    label: 'Wholesaler Number',
    generateOptions: (products: Product[] ): FacetOption[] => {
        const result: FacetOption[] = [];
        WHOLESALER_NUMBER_OPTIONS.forEach( (wholesalerOption: FacetOption ) => {
            // add the Option to the facet only if there is at least one Product that corresponding property
            // set to non-null
            const wholesalerProperty: keyof Product = wholesalerOption.value as keyof Product;
            if ( (products || []).some((product: Product) => !!product[wholesalerProperty]) ) {
                result.push(wholesalerOption);
            }
        });
        return result;
    },
    evaluateProduct: (product: Product, facetValue: FacetValue): boolean => {
        if ( facetValue === undefined ) {
            return true;
        }
        // 'Wholesaler Number' property of the Product should not be empty
        return !!product[facetValue as keyof Product];
    }
};


export const FACET_CONFIG: FacetConfig[] = [
    FACET_PRODUCT,
    FACET_STRENGTH,
    FACET_PRESERVATIVES,
    FACET_EPINEPHRINE,
    FACET_FILL_VOLUME,
    FACET_DELIVERY_SYSTEM,
    FACET_WHOLESALER_NUMBER
];


export function evaluateProduct( product: Product, facetSelections: MapFacetSelections ): boolean {
    for (const facetConfig of FACET_CONFIG) {
        const facetValue: FacetValue = facetSelections[facetConfig.id];
        if ( !facetConfig.evaluateProduct(product, facetValue) ) {
            return false;
        }
    }
    return true;
}
